import "./Page09.css";
import logoBig from "../../assets/logoBig.png";

export const Page09 = () => {
  return (
    <div className="page09__container">
      <img src={logoBig} alt="logo" className="page09__logo" />
      <div className="logoBox" />
    </div>
  );
};
