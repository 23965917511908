import Map from "../../components/Map";
import "./Page08.css";
import { Parallax } from "react-scroll-parallax";

export const Page08 = () => {
  return (
    <div className="page08__container" id="contact">
      <div className="page08__gray">
        <Parallax
          style={{
            position: "absolute",
            right: "20%",
            top: "-50%",
            zIndex: -1,
          }}
          speed={30}
        >
          <svg
            style={{ filter: "blur(5px)", opacity: 0.6 }}
            xmlns="http://www.w3.org/2000/svg"
            width="374"
            height="383"
            viewBox="0 0 374 383"
            fill="none"
          >
            <g opacity="0.6" filter="url(#filter0_if_24_434)">
              <path
                d="M364 200.29C364 289.543 291.997 373 203.177 373C114.356 373 88.4785 300.646 88.4785 211.393C88.4785 122.139 -48.9272 10 39.8931 10C128.713 10 364 111.036 364 200.29Z"
                fill="url(#paint0_linear_24_434)"
              />
            </g>
            <defs>
              <filter
                id="filter0_if_24_434"
                x="0"
                y="0"
                width="374"
                height="387.8"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="27" />
                <feGaussianBlur stdDeviation="7.4" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_24_434"
                />
                <feGaussianBlur
                  stdDeviation="5"
                  result="effect2_foregroundBlur_24_434"
                />
              </filter>
              <linearGradient
                id="paint0_linear_24_434"
                x1="187"
                y1="10"
                x2="187"
                y2="373"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8CABFF" />
                <stop offset="0.15625" stop-color="#4477CE" />
                <stop offset="0.401042" stop-color="#512B81" />
                <stop offset="0.661458" stop-color="#35155D" />
              </linearGradient>
            </defs>
          </svg>
        </Parallax>
        <div className="page08__inside-container">
          <Map />
          <div className="page08__address-container">
            <p className="page08__address-row">Uludağ University</p>
            <p className="page08__address-row">ULUTEK 933/315</p>
            <p className="page08__address-row">Nilüfer - Bursa - Türkiye</p>
            <p className="page08__address-row">info@digimeta.com.tr</p>
            <p className="page08__address-row">+90 224 442 83 43</p>
          </div>
        </div>
      </div>
    </div>
  );
};
