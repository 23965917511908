import "./Page02.css";
import { Parallax } from "react-scroll-parallax";

export const Page02 = () => {
  return (
    <div className="page02__container">
      <div className="page02__title-container">
        <p>Innovate</p>
        <p>Design</p>
        <p>&nbsp;Revolutionize.</p>
      </div>
      <div className="page02__gray">
        <Parallax
          style={{
            position: "absolute",
            left: "20%",
            top: "-50%",
            zIndex: -1,
          }}
          speed={5}
        >
          <svg
            style={{ filter: "blur(5px)", opacity: 0.6 }}
            xmlns="http://www.w3.org/2000/svg"
            width="374"
            height="383"
            viewBox="0 0 374 383"
            fill="none"
          >
            <g opacity="0.6" filter="url(#filter0_if_17_5)">
              <path
                d="M364 200.29C364 289.543 291.997 373 203.177 373C114.356 373 88.4785 300.646 88.4785 211.393C88.4785 122.139 -48.9272 10 39.8931 10C128.713 10 364 111.036 364 200.29Z"
                fill="url(#paint0_linear_17_5)"
              />
            </g>
            <defs>
              <filter
                id="filter0_if_17_5"
                x="0"
                y="0"
                width="374"
                height="387.8"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="27" />
                <feGaussianBlur stdDeviation="7.4" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_17_5"
                />
                <feGaussianBlur
                  stdDeviation="5"
                  result="effect2_foregroundBlur_17_5"
                />
              </filter>
              <linearGradient
                id="paint0_linear_17_5"
                x1="187"
                y1="10"
                x2="187"
                y2="373"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8CABFF" />
                <stop offset="0.15625" stop-color="#4477CE" />
                <stop offset="0.401042" stop-color="#512B81" />
                <stop offset="0.661458" stop-color="#35155D" />
              </linearGradient>
            </defs>
          </svg>
        </Parallax>
        <Parallax
          style={{
            position: "absolute",
            left: "60%",
            top: "0%",
            zIndex: -1,
          }}
          speed={30}
        >
          <svg
            style={{ filter: "blur(5px)" }}
            xmlns="http://www.w3.org/2000/svg"
            width="254"
            height="217"
            viewBox="0 0 254 217"
            fill="none"
          >
            <g filter="url(#filter0_f_18_18)">
              <path
                d="M244 185.229C244 249.746 191.617 150.317 127 150.317C62.3827 150.317 10 249.747 10 185.229C10 120.712 62.3827 10 127 10C191.617 10 244 120.711 244 185.229Z"
                fill="url(#paint0_linear_18_18)"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_18_18"
                x="0"
                y="0"
                width="254"
                height="217"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="5"
                  result="effect1_foregroundBlur_18_18"
                />
              </filter>
              <linearGradient
                id="paint0_linear_18_18"
                x1="127"
                y1="68.4097"
                x2="127"
                y2="302.049"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8CABFF" />
                <stop offset="0.34375" stop-color="#4477CE" />
                <stop offset="0.666667" stop-color="#512B81" />
                <stop offset="1" stop-color="#35155D" />
              </linearGradient>
            </defs>
          </svg>
        </Parallax>
        <Parallax
          style={{
            position: "absolute",
            left: "30%",
            top: "40%",
            zIndex: -1,
          }}
          speed={75}
        >
          <svg
            style={{ filter: "blur(5px)" }}
            xmlns="http://www.w3.org/2000/svg"
            width="123"
            height="123"
            viewBox="0 0 123 123"
            fill="none"
          >
            <g filter="url(#filter0_f_18_46)">
              <circle
                cx="61.5"
                cy="61.5"
                r="51.5"
                fill="url(#paint0_linear_18_46)"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_18_46"
                x="0"
                y="0"
                width="123"
                height="123"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="5"
                  result="effect1_foregroundBlur_18_46"
                />
              </filter>
              <linearGradient
                id="paint0_linear_18_46"
                x1="61.5"
                y1="10"
                x2="61.5"
                y2="113"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8CABFF" />
                <stop offset="0.34375" stop-color="#4477CE" />
                <stop offset="0.666667" stop-color="#512B81" />
                <stop offset="1" stop-color="#35155D" />
              </linearGradient>
            </defs>
          </svg>
        </Parallax>
        <Parallax
          style={{
            position: "absolute",
            left: "70%",
            top: "100%",
            zIndex: -1,
          }}
          speed={50}
        >
          <svg
            style={{ filter: "blur(5px)" }}
            xmlns="http://www.w3.org/2000/svg"
            width="182"
            height="182"
            viewBox="0 0 182 182"
            fill="none"
          >
            <g filter="url(#filter0_if_17_8)">
              <circle cx="91" cy="91" r="81" fill="url(#paint0_linear_17_8)" />
            </g>
            <defs>
              <filter
                id="filter0_if_17_8"
                x="0"
                y="0"
                width="182"
                height="182"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="3" dy="-7" />
                <feGaussianBlur stdDeviation="4.3" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_17_8"
                />
                <feGaussianBlur
                  stdDeviation="5"
                  result="effect2_foregroundBlur_17_8"
                />
              </filter>
              <linearGradient
                id="paint0_linear_17_8"
                x1="91"
                y1="10"
                x2="91"
                y2="172"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8CABFF" />
                <stop offset="0.34375" stop-color="#4477CE" />
                <stop offset="0.666667" stop-color="#512B81" />
                <stop offset="1" stop-color="#35155D" />
              </linearGradient>
            </defs>
          </svg>
        </Parallax>
        <div className="page02__text-container">
          <p>
            Welcome to Digimeta, where the future of digital transformation
            unfolds.
          </p>
          <p>
            Here, we blend strategy, technology, design, and innovation to
            redefine your digital journey. We don't just talk about the future;
            we create it. Join us in this journey of transformation.
          </p>
        </div>
      </div>
    </div>
  );
};
