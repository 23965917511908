import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <div className="App">
      <Router>
        <ParallaxProvider>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
          </Routes>
        </ParallaxProvider>
      </Router>
    </div>
  );
}

export default App;
