import "./Page06.css";
import mikroterra from "../../assets/mikroterra.png";
import drt from "../../assets/drt.png";
import vanemar from "../../assets/vanemar.png";
import { Parallax } from "react-scroll-parallax";

export const Page06 = () => {
  return (
    <div className="page06__container">
      <div className="page06__gray">
        <Parallax
          style={{
            position: "absolute",
            left: "50%",
            top: "70%",
            zIndex: -1,
          }}
          speed={40}
        >
          <svg
            style={{ filter: "blur(5px)" }}
            xmlns="http://www.w3.org/2000/svg"
            width="115"
            height="115"
            viewBox="0 0 115 115"
            fill="none"
          >
            <g filter="url(#filter0_if_24_433)">
              <circle
                cx="57.5"
                cy="57.5"
                r="47.5"
                fill="url(#paint0_linear_24_433)"
              />
            </g>
            <defs>
              <filter
                id="filter0_if_24_433"
                x="0"
                y="0"
                width="115"
                height="115"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="3" dy="-7" />
                <feGaussianBlur stdDeviation="4.3" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_24_433"
                />
                <feGaussianBlur
                  stdDeviation="5"
                  result="effect2_foregroundBlur_24_433"
                />
              </filter>
              <linearGradient
                id="paint0_linear_24_433"
                x1="57.5"
                y1="10"
                x2="57.5"
                y2="105"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8CABFF" />
                <stop offset="0.34375" stop-color="#4477CE" />
                <stop offset="0.666667" stop-color="#512B81" />
                <stop offset="1" stop-color="#35155D" />
              </linearGradient>
            </defs>
          </svg>
        </Parallax>
        <div className="page06__logos-container">
          <img src={mikroterra} alt="Mikroterra" className="page06__company" />
          <img src={drt} alt="DRT" className="page06__company" />
          <img src={vanemar} alt="Vanemar" className="page06__company" />
        </div>
      </div>
    </div>
  );
};
