import { Parallax } from "react-scroll-parallax";
import { GrayStrip } from "../../components/GrayStrip";
import "./Page07.css";

export const Page07 = () => {
  return (
    <div style={{ position: "relative" }}>
      <Parallax
        style={{
          position: "absolute",
          left: "0%",
          top: "-50%",
          zIndex: -1,
        }}
        speed={100}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="444"
          height="903"
          viewBox="0 0 444 903"
          fill="none"
        >
          <g filter="url(#filter0_i_18_175)">
            <path
              d="M308.5 422.5C308.5 655.84 583.34 902.5 350 902.5C116.66 902.5 -536.5 655.84 -536.5 422.5C-536.5 189.16 -347.34 0 -114 0C119.34 0 308.5 189.16 308.5 422.5Z"
              fill="url(#paint0_linear_18_175)"
            />
          </g>
          <defs>
            <filter
              id="filter0_i_18_175"
              x="-536.5"
              y="-4"
              width="983.442"
              height="906.5"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="3" dy="-4" />
              <feGaussianBlur stdDeviation="18" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_18_175"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18_175"
              x1="515.5"
              y1="11"
              x2="515.5"
              y2="856"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.34375" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
      <GrayStrip
        row01Title={"WHERE"}
        row02text={"are based in Bursa, Türkiye, a hub of digital innovation."}
        row03Title={"ARE"}
      />
    </div>
  );
};
