import "./GrayStrip.css";

export const GrayStrip = ({ row01Title, row03Title, row02text }) => {
  return (
    <div className="graystrip__container">
      <div className="graystrip__title-container">
        <div className="graystrip__title">{row01Title}</div>
      </div>
      <div className="graystrip__title-container graystrip__middle">
        <div className="graystrip__title">WE</div>
        <p className="graystrip__middle-text">{row02text}</p>
      </div>
      <div className="graystrip__title-container">
        <div className="graystrip__title">{row03Title}</div>
      </div>
    </div>
  );
};
