import { Parallax } from "react-scroll-parallax";
import { Card } from "../../components/Card";
import "./Page04.css";

const cardContent = [
  {
    id: 1,
    text01: "Digital",
    text02: "Strategy",
    text03: "Development",
  },
  {
    id: 2,
    text01: "Technology",
    text02: "Integration",
  },
  {
    id: 3,
    text01: "Software",
    text02: "&",
    text03: "Application",
    text04: "Development",
  },
  {
    id: 4,
    text01: "Data Analytics",
    text02: "&",
    text03: "Big Data Solutions",
  },
  {
    id: 5,
    text01: "User Experience",
    text02: "&",
    text03: "Design",
  },
  {
    id: 6,
    text01: "Training",
    text02: "&",
    text03: "Consulting",
  },
];

export const Page04 = () => {
  return (
    <div className="page04__container">
      <Parallax
        style={{
          position: "absolute",
          left: "0%",
          bottom: "30%",
          zIndex: -1,
        }}
        speed={25}
      >
        <svg
          style={{ filter: "blur(20px)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="713"
          height="568"
          viewBox="0 0 713 568"
          fill="none"
        >
          <g filter="url(#filter0_f_18_123)">
            <path
              d="M638.751 388.5C638.751 566.612 513.863 493 335.751 493C157.639 493 40 605.112 40 427C40 248.888 392.388 40.0001 570.5 40.0001C748.612 40.0001 638.751 210.388 638.751 388.5Z"
              fill="url(#paint0_linear_18_123)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_18_123"
              x="0"
              y="0"
              width="712.262"
              height="567.821"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="20"
                result="effect1_foregroundBlur_18_123"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18_123"
              x1="404.249"
              y1="-16.5498"
              x2="404.249"
              y2="628.45"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.34375" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
      <Parallax
        style={{
          position: "absolute",
          right: "0%",
          bottom: "30%",
          zIndex: -1,
        }}
        speed={50}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="468"
          height="291"
          viewBox="0 0 468 291"
          fill="none"
        >
          <g filter="url(#filter0_f_85_5)">
            <path
              d="M436.091 210.072C371.592 282.634 236.266 299.671 163.592 235.072C90.9171 170.473 -31.1426 139.714 33.3565 67.1513C97.8555 -5.41122 208.417 2.55259 281.091 67.1513C353.766 131.75 500.59 137.509 436.091 210.072Z"
              fill="url(#paint0_radial_85_5)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_85_5"
              x="0.153992"
              y="0.672607"
              width="466.916"
              height="289.531"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect1_foregroundBlur_85_5"
              />
            </filter>
            <radialGradient
              id="paint0_radial_85_5"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(140 258.5) rotate(132.638) scale(345.959 417.199)"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.338542" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </radialGradient>
          </defs>
        </svg>
      </Parallax>
      {cardContent.map((card) => (
        <Card
          key={card.id}
          text01={card.text01}
          text02={card.text02}
          text03={card.text03}
          text04={card.text04}
        />
      ))}
    </div>
  );
};
