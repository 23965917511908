import { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const mapBoxAccessToken =
  "pk.eyJ1IjoibWlrcm90ZXJyYSIsImEiOiJjbHB3cW1qY2EwaTBjMmlwZnB6ZWlmam5wIn0.2J_pVYo8Wx4H9SDkDVapPg";

var map;
var markers = [{ longitude: 28.857914385867865, latitude: 40.22266543088442 }];

const Map = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = mapBoxAccessToken;

    map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mikroterra/clpwpy2gz01k801p9b7s19dlx",
      center: [28.857914385867865, 40.22266543088442],
      zoom: 14,
    });

    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    if (markers) {
      markers.forEach((marker) => {
        const el = document.createElement("div");
        el.className = "marker";
        el.style.width = "32px";
        el.style.height = "32px";
        const img = document.createElement("img");
        img.src = "https://mktrcdn.s3.us-east-1.amazonaws.com/appIcons/gps.svg";
        img.style.width = "32px";
        img.style.height = "32px";
        img.style.marginTop = "-25px";
        el.appendChild(img);

        new mapboxgl.Marker(el)
          .setLngLat([marker.longitude, marker.latitude])
          .addTo(map);
      });
    }
  }, []);

  return <div ref={mapContainerRef} className="page08__map" />;
};

export default Map;
