import "./Card.css";

export const Card = ({ text01, text02, text03, text04 }) => {
  return (
    <div className="card__container">
      <div></div>
      <div className="card__title-contanier">
        <p className="card__title">{text01}</p>
        <p className="card__title">{text02}</p>
        <p className="card__title">{text03}</p>
        <p className="card__title">{text04}</p>
      </div>
      <button className="card__button">Learn</button>
    </div>
  );
};
