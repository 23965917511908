import { Parallax } from "react-scroll-parallax";
import { GrayStrip } from "../../components/GrayStrip";
import "./Page05.css";

export const Page05 = () => {
  return (
    <div style={{ position: "relative" }}>
      <GrayStrip
        row01Title={"WHO"}
        row02text={
          "work with innovators and leaders to sculpt the digital tomorrow."
        }
        row03Title={"WORK"}
      />
      <Parallax
        style={{
          position: "absolute",
          left: "70%",
          top: "20%",
          zIndex: -1,
        }}
        speed={40}
      >
        <svg
          style={{ filter: "blur(5px)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="182"
          height="182"
          viewBox="0 0 182 182"
          fill="none"
        >
          <g filter="url(#filter0_if_18_173)">
            <circle cx="91" cy="91" r="81" fill="url(#paint0_linear_18_173)" />
          </g>
          <defs>
            <filter
              id="filter0_if_18_173"
              x="0"
              y="0"
              width="182"
              height="182"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="3" dy="-7" />
              <feGaussianBlur stdDeviation="4.3" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_18_173"
              />
              <feGaussianBlur
                stdDeviation="5"
                result="effect2_foregroundBlur_18_173"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18_173"
              x1="91"
              y1="10"
              x2="91"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.34375" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
      <Parallax
        style={{
          position: "absolute",
          left: "40%",
          top: "50%",
          zIndex: -1,
        }}
        speed={75}
      >
        <svg
          style={{ filter: "blur(5px)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="378"
          height="379"
          viewBox="0 0 378 379"
          fill="none"
        >
          <g filter="url(#filter0_f_18_174)">
            <path
              d="M288.943 350.012C224.444 422.574 264.931 258.379 192.256 193.78C119.581 129.182 -38.7344 188.642 25.7647 116.079C90.2637 43.5166 259.858 -28.6324 332.533 35.9664C405.207 100.565 353.443 277.449 288.943 350.012Z"
              fill="url(#paint0_linear_18_174)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_18_174"
              x="0.648674"
              y="0.861084"
              width="377.255"
              height="377.296"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="5"
                result="effect1_foregroundBlur_18_174"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18_174"
              x1="274.14"
              y1="101.659"
              x2="40.568"
              y2="364.432"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.34375" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
    </div>
  );
};
