import "./Page03.css";
import { GrayStrip } from "../../components/GrayStrip";
import { Parallax } from "react-scroll-parallax";

export const Page03 = () => {
  return (
    <div style={{ position: "relative" }}>
      <GrayStrip
        row01Title={"WHAT"}
        row02text={"at Digimeta, transform digital potential into reality."}
        row03Title={"DO"}
      />
      <Parallax
        style={{
          position: "absolute",
          left: "20%",
          top: "20%",
          zIndex: -1,
        }}
        speed={25}
      >
        <svg
          style={{ filter: "blur(10px)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="111"
          height="111"
          viewBox="0 0 111 111"
          fill="none"
        >
          <g filter="url(#filter0_if_18_109)">
            <circle
              cx="55.5"
              cy="55.5"
              r="35.5"
              fill="url(#paint0_linear_18_109)"
            />
          </g>
          <defs>
            <filter
              id="filter0_if_18_109"
              x="0"
              y="0"
              width="111"
              height="111"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="3" dy="-7" />
              <feGaussianBlur stdDeviation="4.3" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_18_109"
              />
              <feGaussianBlur
                stdDeviation="10"
                result="effect2_foregroundBlur_18_109"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18_109"
              x1="55.5"
              y1="20"
              x2="55.5"
              y2="91"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.34375" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
      <Parallax
        style={{
          position: "absolute",
          left: "45%",
          top: "20%",
          zIndex: -1,
        }}
        speed={25}
      >
        <svg
          width="303"
          height="303"
          viewBox="0 0 303 303"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_if_85_4)">
            <circle
              cx="151.5"
              cy="151.5"
              r="136.5"
              fill="url(#paint0_linear_85_4)"
            />
          </g>
          <defs>
            <filter
              id="filter0_if_85_4"
              x="0"
              y="0"
              width="303"
              height="303"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="3" dy="-7" />
              <feGaussianBlur stdDeviation="4.3" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_85_4"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect2_foregroundBlur_85_4"
              />
            </filter>
            <linearGradient
              id="paint0_linear_85_4"
              x1="151.5"
              y1="15"
              x2="151.5"
              y2="288"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.34375" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
      <Parallax
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          zIndex: -1,
        }}
        speed={70}
      >
        <svg
          style={{ filter: "blur(1.5px)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="77"
          height="77"
          viewBox="0 0 77 77"
          fill="none"
        >
          <g filter="url(#filter0_if_18_148)">
            <circle
              cx="38.5"
              cy="38.5"
              r="35.5"
              fill="url(#paint0_linear_18_148)"
            />
          </g>
          <defs>
            <filter
              id="filter0_if_18_148"
              x="0"
              y="-4"
              width="77"
              height="81"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="3" dy="-7" />
              <feGaussianBlur stdDeviation="4.3" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_18_148"
              />
              <feGaussianBlur
                stdDeviation="1.5"
                result="effect2_foregroundBlur_18_148"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18_148"
              x1="38.5"
              y1="3"
              x2="38.5"
              y2="74"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.34375" stop-color="#4477CE" />
              <stop offset="0.666667" stop-color="#512B81" />
              <stop offset="1" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
      <Parallax
        style={{
          position: "absolute",
          right: "0%",
          top: "20%",
          zIndex: -1,
        }}
        speed={100}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="491"
          height="895"
          viewBox="0 0 491 895"
          fill="none"
        >
          <g filter="url(#filter0_i_18_108)">
            <path
              d="M469 364C469 510.297 632.525 894.5 487 894.5C341.475 894.5 0 767.797 0 621.5C0 475.203 323.475 0 469 0C614.525 0 469 217.703 469 364Z"
              fill="url(#paint0_linear_18_108)"
            />
          </g>
          <defs>
            <filter
              id="filter0_i_18_108"
              x="0"
              y="-16"
              width="554.32"
              height="910.5"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="7" dy="-16" />
              <feGaussianBlur stdDeviation="19" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_18_108"
              />
            </filter>
            <linearGradient
              id="paint0_linear_18_108"
              x1="192.5"
              y1="525.5"
              x2="192.5"
              y2="1120.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8CABFF" />
              <stop offset="0.15625" stop-color="#4477CE" />
              <stop offset="0.401042" stop-color="#512B81" />
              <stop offset="0.661458" stop-color="#35155D" />
            </linearGradient>
          </defs>
        </svg>
      </Parallax>
    </div>
  );
};
