import "./HomePage.css";
import { Page01 } from "./HomePages/Page01";
import { Page02 } from "./HomePages/Page02";
import { Page03 } from "./HomePages/Page03";
import { Page04 } from "./HomePages/Page04";
import { Page05 } from "./HomePages/Page05";
import { Page06 } from "./HomePages/Page06";
import { Page07 } from "./HomePages/Page07";
import { Page08 } from "./HomePages/Page08";
import { Page09 } from "./HomePages/Page09";

export const HomePage = () => {
  return (
    <div className="home__container">
      <Page01 />
      <Page02 />
      <Page03 />
      <Page04 />
      <Page05 />
      <Page06 />
      <Page07 />
      <Page08 />
      <Page09 />
    </div>
  );
};
