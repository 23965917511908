import "./Page01.css";
import logoBig from "../../assets/logoBig.png";
import { Header } from "../../components/Header";

export const Page01 = () => {
  return (
    <div className="page01__container">
      <Header />
      <img src={logoBig} alt="logo" className="page01__logo" />
      <div className="logoBox" />
    </div>
  );
};
