import "./Header.css";
import logoSmall from "../assets/logoSmall.png";

export const Header = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className="header__container">
      <img src={logoSmall} alt="logo" className="header__logo" />
      <button onClick={scrollToContact} className="header__button">
        Contact Us
      </button>
    </div>
  );
};
